<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CRow>
      <CCol sm="6">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CIcon name="cil-user" /><strong> {{ account.login }} </strong>
                <div class="card-header-actions">
                  <CBadge v-if="isSuspended" color="danger">suspended</CBadge>
                  <CBadge v-else :color="$config.userClr(account.flags)">{{ $config.userTxt(account.flags) }}</CBadge>
                </div>
              </CCardHeader>
              <CCardBody>
                <dl class="row">
                  <dt class="col-sm-3">Login</dt>
                  <dd class="col-sm-9">{{ account.login }}</dd>

                  <dt class="col-sm-3">Email</dt>
                  <dd class="col-sm-9">{{ account.email }}</dd>

                  <dt class="col-sm-3">Game Key</dt>
                  <dd class="col-sm-9">{{ account.product_Key }}</dd>

                  <dt class="col-sm-3">Expiration</dt>
                  <dd class="col-sm-9">{{ account.product_Expiration | formatDate }}</dd>

                  <dt v-if="isSuspended" class="col-sm-3">Suspension End</dt>
                  <dd v-if="isSuspended" class="col-sm-9">{{ suspensionEnd | formatDate }}</dd>

                  <dt v-if="account.consumeAttempts > 5" class="col-sm-3">Key Lock</dt>
                  <dd v-if="account.consumeAttempts > 5" class="col-sm-9">{{ account.consumeAttempts }} Attempts</dd>

                  <dt class="col-sm-3">Discord</dt>
                  <dd class="col-sm-9">{{ account.discord }}</dd>

                  <dt class="col-sm-3">Product</dt>
                  <dd class="col-sm-9">{{ account.product }}</dd>

                  <dt class="col-sm-3">Flags</dt>
                  <dd class="col-sm-9">
                    <div
                      class="custom-control custom-checkbox"
                      v-for="flag in flags"
                      :key="flag.id" >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="flag.id"
                        :value="flag.id"
                        v-model="selectedFlags" />
                      <label class="custom-control-label" :for="flag.id">{{ flag.flag }}</label>
                    </div>
                  </dd>
                </dl>
              </CCardBody>
              <CCardFooter>
                <CRow style="justify-content: space-between; margin-left: 5px; margin-right:5px;">
                  <CButton color="primary" class="px-4" @click="updateFlags" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      {{ mainBtnLabel }}
                  </CButton>
                  <CButton v-if="account.consumeAttempts > 5" color="info" class="px-4" @click="removeKeyLock" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      Clear Key Lock
                  </CButton>
                  <CButton v-if="account.flagship" color="danger" class="px-4" @click="refundModal = true" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      {{ refundBtnLabel }}
                  </CButton>
                </CRow>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CIcon name="cil-money" /><strong>Subscription</strong>
                <div class="card-header-actions">
                  <CBadge :color="$config.subClr(subscription.status_id)">{{
                    $config.subTxt(subscription.status_id)
                  }}</CBadge>
                </div>
              </CCardHeader>
              <CCardBody>
                <dl class="row">
                  <dt class="col-sm-3">ID</dt>
                  <dd class="col-sm-9">{{ subscription.id }}</dd>

                  <dt class="col-sm-3">Status</dt>
                  <dd class="col-sm-9">{{ subscription.status_id }}</dd>

                  <dt class="col-sm-3">Expiration</dt>
                  <dd class="col-sm-9">{{ subscription.expiration_date | formatDate }}</dd>

                  <dt class="col-sm-3">Next Billing</dt>
                  <dd class="col-sm-9">{{ subscription.first_billing_attempt | formatDate }}</dd>

                  <dt class="col-sm-3">Last Modified</dt>
                  <dd class="col-sm-9">{{ subscription.modified | formatDate }}</dd>
                </dl>
              </CCardBody>
              <CCardFooter v-if="subscription.status_id == 'TOP'">
                <CRow style="justify-content: space-between; margin-left: 5px; margin-right:5px;">
                  <CButton color="primary" class="px-4" @click="syncExpirations" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      Sync Expirations
                  </CButton>
                  <CButton v-if="subscription.status_id == 'TOP'" color="danger" class="px-4" @click="cancelSubModal = true" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      Cancel
                  </CButton>
                </CRow>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CCard>
                <CCardHeader>
                    <CIcon name="cil-gamepad"/><strong> Suspend Account </strong>
                </CCardHeader>
                <CCardBody>
                    <span v-if="isSuspended">Account is currently suspended</span>
                    <CSelect v-else
                      label="Length"
                      horizontal
                      :options="suspensionTimeOptions"
                      :value.sync="selectedSuspensionTime"
                      placeholder="in Days" />
                </CCardBody>
                  <CCardFooter>
                    <CButton v-if="isSuspended" color="primary" class="px-4" @click="processSuspension" :disabled="isUpdating">
                        <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                        Remove Suspension
                    </CButton>
                    <CButton v-else color="danger" class="px-4" @click="processSuspension" :disabled="isUpdating">
                        <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                        Suspend
                    </CButton>
                </CCardFooter>
            </CCard> 
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="6">
        <CRow v-if="!account.flagship" >
            <CCol>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cil-gamepad"/><strong> Activate Product </strong>
                  </CCardHeader>
                  <CCardBody>
                      <CSelect
                        label="Product"
                        horizontal
                        :options="products"
                        :value.sync="selectedProduct"
                        placeholder="Select Product" />
                      <CInput label="Expires" type="date" v-model="expires" horizontal />
                  </CCardBody>
                   <CCardFooter>
                      <CButton color="primary" class="px-4" @click="activateProduct" :disabled="isUpdating">
                          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                          {{ addkeyBtnLabel }}
                      </CButton>
                  </CCardFooter>
              </CCard>
            </CCol>
        </CRow>
        <CRow v-if="account.flagship">
            <CCol>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cil-clock"/><strong> Extend Expiration </strong>
                  </CCardHeader>
                  <CCardBody>
                      <CInput label="UTC Date" type="date" v-model="newExpDate" horizontal />
                      <CInput label="UTC Time" type="time" v-model="newExpTime" horizontal />
                  </CCardBody>
                   <CCardFooter>
                      <CButton color="primary" class="px-4" @click="extendModal = true" :disabled="isUpdating || !newExpDate">
                          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                          Add Time
                      </CButton>
                  </CCardFooter>
              </CCard>
            </CCol>
        </CRow>
        <CRow >
            <CCol>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cil-gamepad"/><strong> Add Gift Keys </strong>
                  </CCardHeader>
                  <CCardBody>
                      <CSelect
                        label="Product"
                        horizontal
                        :options="giftkeyproducts"
                        :value.sync="selectedGiftProd"
                        placeholder="Select Product" />
                      <CInput label="Quantity" type="number" v-model="giftKeyQty" horizontal />
                  </CCardBody>
                   <CCardFooter>
                      <CButton color="primary" class="px-4" @click="addGiftKeys" :disabled="isUpdating">
                          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                          {{ addGiftKeyLabel }}
                      </CButton>
                  </CCardFooter>
              </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
              <CCard>
                  <CCardHeader>
                      <CIcon name="cib-twitch"/><strong> Affiliate </strong>
                  </CCardHeader>
                  <CCardBody>
                    <dl class="row">
                      <dt class="col-sm-3">Tag</dt>
                      <dd class="col-sm-9" v-if="account.affiliateKey">{{ account.affiliateKey }}</dd>
                      <dd class="col-sm-9" v-else><CInput type="text" horizontal v-model="newAffiliateKey"  /></dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">ID</dt>
                      <dd class="col-sm-9" v-if="account.affiliateId">{{ account.affiliateId }}</dd>
                      <dd class="col-sm-9" v-else><CInput type="text" horizontal v-model="newAffiliateId"  /></dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">Active</dt>
                      <dd class="col-sm-9">
                        <div class="custom-control custom-checkbox" >
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="isAffiliate"
                            v-model="account.isAffiliate" />
                          <label class="custom-control-label" for="isAffiliate"></label>
                        </div>
                      </dd>
                    </dl>
                  </CCardBody>
                   <CCardFooter>
                      <CButton color="primary" class="px-4" @click="updateAffiliate" :disabled="isUpdating">
                          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                          {{ affiliateBtnLabel }}
                      </CButton>
                  </CCardFooter>
              </CCard>
            </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>
                <slot name="header">
                <CIcon name="cil-money" /> Payments
                <div class="card-header-actions">
                    <small class="text-muted">Total: {{ payments.length }}</small>
                </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="payments"
                    :fields="paymentFields"
                    :hover="hover"
                    column-filter
                    items-per-page-select
                    :items-per-page="10"
                    sorter
                    pagination >

                    <template #fAmount="{ item }">
                        <td class="py-2">${{ item.amount }}</td>
                    </template> 
                    <template #fTxnDate="{ item }">
                        <td class="py-2">{{ item.date_of_Txn | formatDate }}</td>
                    </template> 

                </CDataTable>
            </CCardBody>
          </CCard> 
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>
                <slot name="header">
                <CIcon name="cil-gamepad" /> Gift Keys
                <div class="card-header-actions">
                    <small class="text-muted">Total: {{ giftKeys.length }}</small>
                </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="giftKeys"
                    :fields="giftKeyFields"
                    :hover="hover"
                    column-filter
                    items-per-page-select
                    :items-per-page="10"
                    sorter
                    pagination >

                    <template #purchaseDate="{ item }">
                        <td class="py-2">{{ item.purchaseDate | formatDate }}</td>
                    </template> 

                    <template #consumedOn="{ item }">
                        <td class="py-2">{{ item.consumedOn | formatDate }}</td>
                    </template> 
                </CDataTable>
            </CCardBody>
          </CCard> 
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>
                <slot name="header">
                <CIcon name="cil-gift" /> Rewards
                <div class="card-header-actions">
                    <small class="text-muted">Total: {{ rewards.length }}</small>
                </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="rewards"
                    :fields="rewardFields"
                    :hover="hover"
                    column-filter
                    items-per-page-select
                    :items-per-page="10"
                    sorter
                    pagination >
                </CDataTable>
            </CCardBody>
          </CCard> 
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>
                <slot name="header">
                <CIcon name="cil-tags" /> IPN Audits
                <div class="card-header-actions">
                    <small class="text-muted">Total: {{ ipns.length }}</small>
                </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="ipns"
                    :fields="ipnFields"
                    :hover="hover"
                    column-filter
                    items-per-page-select
                    :items-per-page="10"
                    sorter
                    pagination >
                    
                    <template #fAuditDate="{ item }">
                        <td class="py-2">{{ item.audit_Date | formatDate }}</td>
                    </template> 

                    <template #fValid="{ item }">
                        <td class="py-2"><CBadge :color="item.isValid ? 'success' : 'danger'">{{ item.isValid }}</CBadge></td>
                    </template>

                </CDataTable>
            </CCardBody>
          </CCard> 
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
          <CCard>
            <CCardHeader>
                <slot name="header">
                <CIcon name="cil-tags" /> Suspensions
                <div class="card-header-actions">
                    <small class="text-muted">Total: {{ suspensions.length }}</small>
                </div>
                </slot>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="suspensions"
                    :fields="suspensionFields"
                    :hover="hover"
                    column-filter
                    items-per-page-select
                    :items-per-page="10"
                    sorter
                    pagination >
                    
                    <template #fStartDate="{ item }">
                        <td class="py-2">{{ item.suspensionStart | formatDate }}</td>
                    </template> 

                    <template #fEndDate="{ item }">
                        <td class="py-2">{{ item.suspensionEnd | formatDate }}</td>
                    </template>

                </CDataTable>
            </CCardBody>
          </CCard> 
      </CCol>
    </CRow>
    <CModal title="Refund Customer" color="danger"  :show.sync="refundModal" @update:show="closeRefundModal">
      Are you sure you wish to process a refund? This is ir-reversible<br />
      You will still need to remove the Beta role from Discord and the Forums
    </CModal>
    <CModal title="Cancel Subscription" color="danger"  :show.sync="cancelSubModal" @update:show="closeCancelSubModal">
      Are you sure you wish to cancel this users Subscription? This is ir-reversible
    </CModal>
    <CModal title="Extend Expiration" color="info"  :show.sync="extendModal" @update:show="closeExtendModal">
      Extending the Expiration date is ir-reversible. Click ok to continue. 
    </CModal>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from 'moment';

export default {
  name: "Account",
  props: ["id"],
  components: {
    VueJsonPretty,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      refundModal: false,
      cancelSubModal: false,
      extendModal: false,
      isSuspended: false,
      suspensionEnd: null,
      hover: true,
      mainBtnLabel: "Update",
      refundBtnLabel: "Refund",
      addkeyBtnLabel: "Activate",
      addGiftKeyLabel: "Add Gift Keys",
      affiliateBtnLabel: "Update Affiliate",
      expires: null, 
      newExpDate: null,
      newExpTime: null,
      giftKeyQty: 1,
      newAffiliateKey: null,
      newAffiliateId: null,
      account: {},
      subscription: {},
      flags: [],
      selectedFlags: [],
      payments: [],
      paymentFields: [
        { key: "txn_Id", label: "TXN", filter: false, sorter: false },
        { key: "product_Code", label: "Product", filter: false, sorter: false },
        { key: "fAmount", label: "Amount", filter: false, sorter: false },
        { key: "fTxnDate", label: "TXN Date", filter: false, sorter: false }
      ],
      ipns: [],
      ipnFields: [
          { key: "auditId", label: "ID", filter: false, sorter: false },
          { key: "txn_Id", label: "TXN ID", filter: false, sorter: false },
          { key: "transaction_Result", label: "Result", filter: false, sorter: false },
          { key: "fValid", label: "Valid", filter: false, sorter: false },
          { key: "fAuditDate", label: "Audit Date", filter: false, sorter: false }
      ],
      giftKeys: [],
      giftKeyFields: [
          { key: "productKey", label: "Key", filter: false, sorter: false },
          { key: "productCode", label: "Product", filter: false, sorter: false },
          { key: "transactionId", label: "Transaction", filter: false, sorter: false },
          { key: "purchaseDate", label: "Purchase Date", filter: false, sorter: false },
          { key: "consumedBy", label: "Consumed By", filter: false, sorter: false },
          { key: "consumedOn", label: "Consumed Date", filter: false, sorter: false }
      ],
      rewards: [],
      rewardFields: [
          { key: "rewardType", label: "Type", filter: false, sorter: false },
          { key: "rewardName", label: "Name", filter: false, sorter: false },
          { key: "description", label: "Description", filter: false, sorter: false }
      ],
      suspensions: [],
      suspensionFields: [
          { key: "id", label: "Id", filter: false, sorter: false },
          { key: "subId", label: "User Id", filter: false, sorter: false },
          { key: "fStartDate", label: "Start Date", filter: false, sorter: false },
          { key: "fEndDate", label: "End Date", filter: false, sorter: false }
      ],
      products: ['EMBR-LS', 'EMBR-GT1M'],
      giftkeyproducts: ['EMBR-LS', 'EMBR-GT1M'],
      selectedProduct: null,
      selectedGiftProd: null,
      suspensionTimeOptions: [1, 2, 3, 5, 7, 10, 14, 28],
      selectedSuspensionTime: null
    };
  },
  watch: {
      isUpdating: function(){
          this.mainBtnLabel = (this.isUpdating ? 'Please Wait...' : 'Update');
          this.addkeyBtnLabel = (this.isUpdating ? 'Please Wait...' : 'Activate');
          this.affiliateBtnLabel = (this.isUpdating ? 'Please Wait...' : 'Update Affiliate');
          this.refundBtnLabel = (this.isUpdating ? 'Please Wait...' : 'Refund');
          this.addGiftKeyLabel = (this.isUpdating ? 'Please Wait...' : 'Add Gift Keys');
      }
  },
  methods: {
    getAccount() {
      this.isLoading = true;

      this.$http.get(this.$config.gmapi + "/user/" + this.id, {
          headers: this.$config.getHeaders(),
        }).then((response) => {
          return response.json();
        }).then((jsonData) => {
          this.account = jsonData;
        }).catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        }).finally(function () {
          this.getSubScription();
        });
    },
    getSubScription() {
      if (this.account.digitalRiverShopperId && this.account.digitalRiverSubId)
      {
        this.$http.get(this.$config.gmapi + "/user/Subscription?shopper_id=" + this.account.digitalRiverShopperId, {
          headers: this.$config.getHeaders(),
        }).then((response) => {
          return response.json();
        }).then((jsonData) => {
          this.subscription = jsonData;
        }).catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        }).finally(function () {
          this.getFlags();
        });
      } else {
        this.getFlags();
      }
    },
    getFlags() {
        this.flags = [];

        this.$http.get(this.$config.gmapi + '/GameFlag/set?incSub=false', { headers: this.$config.getHeaders() }).then(response => {
            return response.json();
        }).then(jsonData => {
            this.flags = jsonData;
            
          for (let i = 0; i < this.flags.length; i++) {
              if ((this.account.flags & this.flags[i].id) == this.flags[i].id)
                this.selectedFlags.push(this.flags[i].id);
          }

        }).catch(function (error) {
            this.isLoading = false;
            this.$root.$emit('onErr', error);
        }).finally(function (){
            this.getPayments();
        });
    },
    getPayments() {
      this.$http
        .get(this.$config.gmapi + "/txn?userId=" + this.id, {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.payments = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.getIpns();
        });
    },
    getIpns() {
      this.$http
        .get(this.$config.gmapi + "/txn/ipn?userId=" + this.id, {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.ipns = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.getGiftKeys();
        });
    },
    getGiftKeys() {
      this.$http
        .get(this.$config.gmapi + "/user/giftkeys?userId=" + this.id, {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.giftKeys = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.getRewards();
        });
    },
    getRewards() {
      this.$http
        .get(this.$config.gmapi + "/user/rewards/" + this.id, {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.rewards = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.getSuspensions();
        });
    },
    getSuspensions() {
      this.$http
        .get(this.$config.gmapi + "/user/Suspensions/" + this.id, {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.suspensions = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.processSuspensions();
          this.isLoading = false;          
        });
    },
    processSuspensions() {
      this.isSuspended = false;
      this.suspensionEnd = null;

      for (let i = 0; i < this.suspensions.length; i++) {
        if (moment.utc(this.suspensions[i].suspensionEnd).isAfter(moment.utc())) {
          this.isSuspended = true;
          this.suspensionEnd = this.suspensions[i].suspensionEnd;
          break;
        }     
      }
    },
    activateProduct(e) {
      e.preventDefault();

      if (this.expires && this.selectedProduct){
        this.isUpdating = true;
        this.$http.post(this.$config.gmapi + '/user?id=' + this.account.id + '&expires=' + this.expires + '&product_code=' + this.selectedProduct, null, {headers: this.$config.getHeaders() }).then(response => {
            return response.json();
        }).then(jsonData => {
            this.account = jsonData;            
            this.isUpdating = false;
            this.expires = null;
        }).catch(function (error) {
            this.$root.$emit('onErr', error);
            this.isUpdating = false;
        });
      }else {
        this.$root.$emit('sendMsg', "Validation Error", "danger", "Invalid Date");
      }
    },
    addGiftKeys(e) {
      e.preventDefault();

      this.isUpdating = true;
      this.$http.post(this.$config.gmapi + '/user/giftkeys?userid=' + this.account.id + '&productcode=' + this.selectedGiftProd + '&qty=' + this.giftKeyQty, null, {headers: this.$config.getHeaders() }).then(response => {
          return response.json();
      }).then(jsonData => {
          this.giftKeys = jsonData;
          this.isUpdating = false;
      }).catch(function (error) {
          this.$root.$emit('onErr', error);
          this.isUpdating = false;
      });
    },
    updateFlags(e){
        e.preventDefault();
        this.isUpdating = true;
        const arrSum = this.selectedFlags.reduce((a,b) => a + b, 0);
        this.$http.put(this.$config.gmapi + '/user?id=' + this.id + '&flags=' + arrSum, null,  {
          headers: this.$config.getHeaders() })
        .then(response => {
            this.updateMongo(arrSum);
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
            this.isUpdating = false;
        });
    },
    updateMongo(flags) {
      this.$http.put(this.$config.gameapi + '/Player/' + localStorage.env + '/flags/' + this.account.product_Key + '?flags=' + flags, null, {
        headers: this.$config.getHeaders()
      }).then(response => {
          this.isUpdating = false;
          this.selectedFlags = [];
          this.getAccount();
      }).catch(function (error) {
        console.log(JSON.stringify(error));
          this.$root.$emit('sendMsg', "API Error", "danger", "Backend returned ugh (" + error.status +") " + error.statusText );
          this.isUpdating = false;
      });
    },
    clearPwdCache() {
      this.$http.delete(this.$config.gameapi + '/Player/' + localStorage.env + '/clear/' + this.account.product_Key, null, {
        headers: this.$config.getHeaders()
      }).then(response => {
          this.isUpdating = false;
          this.getAccount();
      }).catch(function (error) {
          this.$root.$emit('sendMsg', "API Error", "danger", "Backend returned (" + error.status +") " + error.statusText );
          this.isUpdating = false;
      });
    },
    syncExpirations() { 
      this.isUpdating = true;
      this.$http.put(this.$config.gmapi + '/user/subscription?userId=' + this.account.id, null, {headers: this.$config.getHeaders() }).then(response => {
          this.clearPwdCache();
      }).catch(function (error) {
        console.log(error);
        this.$root.$emit('onErr', error);
          this.isUpdating = false;
      });
    },
    closeRefundModal(status, evt) { 
      if (evt.target.innerText.trim() === "OK") {
          this.isUpdating = true;
          this.$http.post(this.$config.gmapi + '/user/refund/' + this.account.id, null, {headers: this.$config.getHeaders() }).then(response => {
              return response.json();
          }).then(jsonData => {
              this.account = jsonData;
              this.isUpdating = false;
          }).catch(function (error) {
            this.$root.$emit('onErr', error);
              this.isUpdating = false;
          });
        } 
    },
    closeCancelSubModal(status, evt) { 
      if (evt.target.innerText.trim() === "OK") {
        this.isUpdating = true;
        this.$http.delete(this.$config.gmapi + '/user/subscription?userId=' + this.account.id, {headers: this.$config.getHeaders() }).then(response => {
            this.isUpdating = false;
            this.getAccount();
        }).catch(function (error) {
          console.log(error);
          this.$root.$emit('onErr', error);
            this.isUpdating = false;
        });
      } 
    },
    closeExtendModal(status, evt) { 
      if (evt.target.innerText.trim() === "OK") {
        let t = this.newExpTime ? this.newExpTime : "00:00";

        let x = `${this.newExpDate}T${t}Z`;

        this.isUpdating = true;
        this.$http.post(this.$config.gmapi + '/user/subscription?userId=' + this.account.id, { "expiration_date": x }, {headers: this.$config.getHeaders() }).then(response => {
          this.clearPwdCache();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
          this.isUpdating = false;
        });    
      } 
    },
    processSuspension() {
      this.isUpdating = true;

      if (this.isSuspended) {
        this.$http.delete(this.$config.gmapi + '/user/Suspensions?subId=' + this.account.id, {headers: this.$config.getHeaders() }).then(response => {
          this.isUpdating = false;
          this.getAccount();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
          this.isUpdating = false;
        });
      } else {
        if (!this.selectedSuspensionTime || this.selectedSuspensionTime < 1) {
          this.$root.$emit('sendMsg', "Validation Error", "danger", "Invalid Suspension Length");
          this.isUpdating = false;
        } else {
          this.$http.post(this.$config.gmapi + '/user/Suspensions?subId=' + this.account.id + '&days=' + this.selectedSuspensionTime, null, {headers: this.$config.getHeaders() }).then(response => {
            this.clearPwdCache();
          }).catch(function (error) {
            this.$root.$emit('onErr', error);
            this.isUpdating = false;
          });
        }
      }
    },
    removeKeyLock() {
      this.isUpdating = true;

      this.$http.delete(this.$config.gmapi + '/user/KeyLock?subId=' + this.account.id, {headers: this.$config.getHeaders() }).then(response => {
          this.isUpdating = false;
          this.getAccount();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
          this.isUpdating = false;
        });
    },
    updateAffiliate(e) {
      e.preventDefault();
      this.isUpdating = true;

      if (!this.account.affiliateKey && !this.newAffiliateKey){
        this.$root.$emit('sendMsg', "Validation Error", "danger", "Invalid Key");
      } else if (this.account.affiliateKey) {
        this.newAffiliateKey = this.account.affiliateKey;
      }

      if ((!this.account.affiliateId || this.account.affiliateId == 0) && !this.newAffiliateId){
        this.$root.$emit('sendMsg', "Validation Error", "danger", "Invalid Affiliate Id");
      } else if (this.account.affiliateId) {
        this.newAffiliateId = this.account.affiliateId;
      }

      this.$http.put(this.$config.gmapi + '/user/affiliate?id=' + this.account.id + '&affiliateKey=' + this.newAffiliateKey+ '&affiliateId=' + this.newAffiliateId + '&isAffiliate=' + this.account.isAffiliate,  null, {headers: this.$config.getHeaders() }).then(response => {
          return response.json();
      }).then(jsonData => {
          this.account.affiliateKey = this.newAffiliateKey;
          this.account.affiliateId = this.newAffiliateId;
          this.isUpdating = false;
      }).catch(function (error) {
        this.$root.$emit('onErr', error);
          this.isUpdating = false;
      });
    },
  },
  created() {
    this.getAccount();
  },
};
</script>
